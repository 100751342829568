import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const HeaderContact = ({ _data }) => {
  let data = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(heade-contact)/" }) {
          frontmatter {
            subtitle1
            subtitle2
            contactTitle
          }
        }
      }
    `)
    data = result.mdx.frontmatter
  } catch (error) {
    data = _data
  }

  return (
    <div
      id="HeaderContact"
      className=" HeaderContact m-4 text-center pt-3"
      data-testid="HeaderContact"
    >
      <h1 className="title pt-3">{data?.contactTitle}</h1>

      <p className="subTitle">
        {data?.subtitle1}
        <br />
        <span
          className="subTitle"
          dangerouslySetInnerHTML={{ __html: data.subtitle2 }}
        ></span>
      </p>
    </div>
  )
}

export default HeaderContact
