import React from "react"

import Seo from "../seo"
import HeaderContact from "./header-contact"
import ContactComponent from "./contact-component"

const ContactUs = () => {
  return (
    <div id="ContactUs" className="contactUs py-5">
      <Seo
        title="Contact Us"
        description="Please get in touch if you have any question left about Products, Sales, or Technical Support"
        titleog="How Can We Help You?"
        descriptionog="Choose an option below and we’ll be happy to show you how MAXapps can transform your company’s IBM Maximo."
        image="https://maxapps-maximo.com/og/contact-us.jpg"
        url="https://maxapps-maximo.com/contact/"
      />
      <HeaderContact />
      <ContactComponent />
    </div>
  )
}

export default ContactUs
